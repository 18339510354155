const state = () => {
    postPriceServices: []
    postAboutServices: []
    postNews: []
    otherNews: []
    lstOfPost: []
}

const getters = {
    getPostPriceServices(state){
        return state.postPriceServices
    },

    getPostAboutServices(state){
        return state.postAboutServices
    },

    getPostNews(state) {
        return state.postNews
    },

    getOtherNews(state) {
        return state.otherNews
    },

    getAllPosts (state) {
        return state.lstOfPost;
    }
}

const mutations = {
    SetPostPriceServices(state, payload){
        state.postPriceServices = payload;
    },

    SetPostAboutServices(state, payload){
        state.postAboutServices = payload;
    },

    SetPostsNews(state, payload) {
        state.postNews = payload;
    },

    SetOtherNews(state, payload) {
        state.otherNews = payload;
    },

    MergeAllPost (state, payload) {
        if (!state.lstOfPost) {
            state.lstOfPost = []
        }
        state.lstOfPost.push(...payload)
    }
}

const actions = {
    async CommitPostPriceServices({commit}, payload){
        await commit("SetPostPriceServices", payload);
        await commit("MergeAllPost", payload);
    },

    async CommitPostAboutServices({commit}, payload){
        await commit("SetPostAboutServices", payload);
        await commit("MergeAllPost", payload);
    },

    async CommitPostsNewServices({commit}, payload){
        await commit("SetPostsNews", payload);
        await commit("MergeAllPost", payload);
    },

    async CommitOtherNewsServices({commit}, payload){
        await commit("SetOtherNews", payload);
        await commit("MergeAllPost", payload);
    },

    async CommitMergeAllPost({commit}, payload){
        await commit("MergeAllPost", payload);
    }
}
export default {
    state,
    getters,
    mutations,
    actions
}