import https from 'https';

export default function ({ $axios, error: nuxtError }) {
    $axios.defaults.httpsAgent = new https.Agent({ rejectUnauthorized: false });
    $axios.onError(error => {
        nuxtError({
            statusCode: error.response.status,
            message: error.message,
        });
        return Promise.resolve(false);
    })
    $axios.onResponse(response => {
        return response.data
    })
}