import commentServices from "~/services/comment/comment-services";
const actions = {
    async NewComment(payload){
        const axios = payload.axios;
        const body = payload.body;
        await commentServices.newComment(axios, body);
    },
}
export default {
    actions
}