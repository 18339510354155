import Navigation from '@/store/navigation/navigation.module'
import Post from '@/store/post/post.module'
import Comment from '@/store/comment/comment.module'
import NavigationServices from '@/services/navigation/navigation-services'
import PostServices from '~/services/post/post-services'
import AuthenticationServices from '~/services/authentication/authen-services'
import _ from 'lodash'
import { PostEnum } from '~/core/enum'
import blogData from '@/content/Posts.json'
import navigationData from '@/content/Navigations.json'
export const state = () => ({
    ...Navigation.state,
    ...Post.state,
    token: {}
})

export const getters = {
    ...Navigation.getters,
    ...Post.getters,
    getToken(state){
        return state.token
    }
}

export const mutations = {
    ...Navigation.mutations,
    ...Post.mutations,
    setToken(state, payload) {
        state.token = payload.token;
    }
}

export const actions = {
    ...Navigation.actions,
    ...Post.actions,
    ...Comment.actions,
    async nuxtServerInit ({ commit }, { $axios, redirect }) {
        Navigation.actions.CommitNavigations({commit}, navigationData);
        if (!_.isNil(blogData)) {
            await Post.actions.CommitPostPriceServices({commit}, blogData.filter(x => x.type === PostEnum.FEE));
            await Post.actions.CommitPostAboutServices({commit}, blogData.filter(x => x.type === PostEnum.ABOUT));
            await Post.actions.CommitPostsNewServices({commit}, blogData.filter(x => x.type === PostEnum.NEWS));
            await Post.actions.CommitOtherNewsServices({commit}, blogData.filter(x => x.type === PostEnum.OTHER));
            return;
        }
        await Post.actions.CommitPostPriceServices({commit}, []);
        await Post.actions.CommitPostAboutServices({commit}, []);
        await Post.actions.CommitPostsNewServices({commit}, []);
        await Post.actions.CommitOtherNewsServices({commit}, []);
    }
}