const state = () => {
    navigations: []
}

const getters = {
    getNavigations(state){
        return state.navigations
    }
}

const mutations = {
    SetNavigations(state, payload){
        state.navigations = payload;
    }
}

const actions = {
    CommitNavigations({commit}, payload){
        commit("SetNavigations", payload);
    }
}
export default {
    state,
    getters,
    mutations,
    actions
}