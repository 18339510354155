const chatbox = document.getElementById('fb-customer-chat');
chatbox?.setAttribute("page_id", "108634228587663");
chatbox?.setAttribute("attribution", "biz_inbox");
window.fbAsyncInit = function() {
  FB.init({
    appId      : '726307808658920',
    xfbml      : true,
    version    : 'v16.0'
  });
  FB.AppEvents.logPageView();
};

(function(d, s, id){
   let js, fjs = d.getElementsByTagName(s)[0];
   if (d.getElementById(id)) {return;}
   js = d.createElement(s); js.id = id;
   js.src = "https://connect.facebook.net/vi_VN/sdk.js";
   fjs.parentNode.insertBefore(js, fjs);
 }(document, 'script', 'facebook-jssdk'));